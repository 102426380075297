//  ## DUPLICATE FILE NAME DOWNLOAD WITH NUMBER at END EX:- test(1).JPG ##

import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

function DownloadButton({ isCheckAll, ImgUri, downloadImages, wo, isCheck, totalCount }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentDownloadList, setCurrentDownloadList] = useState([]);
  const [downloadCount, setDownloadCount] = useState(0);

  useEffect(() => {
    // let updatedDownloadList = isCheckAll ? ImgUri : downloadImages;
    let updatedDownloadList = downloadImages;
    setCurrentDownloadList(updatedDownloadList);
  }, [isCheckAll, ImgUri, downloadImages]);

  const handleDownload = async () => {
    setIsDownloading(true);
    const filenames = new Set(); 
    if (currentDownloadList.length <= 10) {
      for (const item of currentDownloadList) {
        const [fileurl, downloadFileName, downloadFileNameExtension] = item.split("$");
        try {
          const response = await fetch(fileurl);
          const blob = await response.blob();
          const a = document.createElement("a");
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = `${downloadFileName}.${downloadFileNameExtension}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); // Clean up
          URL.revokeObjectURL(url);
          setDownloadCount((prevCount) => prevCount + 1);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      }
    } else {
      const zip = new JSZip();
      for (let index = 0; index < currentDownloadList.length; index++) {
        const [fileurl, downloadFileName, downloadFileNameExtension] = currentDownloadList[index].split("$");
        let uniqueName = downloadFileName;
        let counter = 1;
        while (filenames.has(uniqueName + '.' + downloadFileNameExtension)) {
          uniqueName = `${downloadFileName}(${counter})`;
          counter++;
        }
        filenames.add(uniqueName + '.' + downloadFileNameExtension);
        try {
          const response = await fetch(fileurl);
          const blob = await response.blob();
          zip.file(`${uniqueName}.${downloadFileNameExtension}`, blob);
          setDownloadCount(index + 1); // Update the counter immediately for each file added to the zip
          if (index === currentDownloadList.length - 1) {
            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, "WorkOrder_" + wo + ".zip");
          }
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      }
    }
    setIsDownloading(false);
  };  

  const buttonText = currentDownloadList.length > 10 ? "Download Zip" : "Download";

  return (
      <button
        className={`btn-download ${isDownloading ? "is-downloading" : ""}`}
        onClick={handleDownload}
        disabled={isDownloading || !currentDownloadList.length || isCheck === 0}
      >
        {isDownloading ? `Downloading...(${Math.round(downloadCount/totalCount*100)}%)` : buttonText}
      </button>
  );
}

export default DownloadButton;

// import React, { useState, useEffect } from "react";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";

// function DownloadButton({ isCheckAll, ImgUri, downloadImages, wo, isCheck, totalCount }) {
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [currentDownloadList, setCurrentDownloadList] = useState([]);
//   const [downloadCount, setDownloadCount] = useState(0);

//   useEffect(() => {
//     // let updatedDownloadList = isCheckAll ? ImgUri : downloadImages;
//     let updatedDownloadList =  downloadImages;
//     setCurrentDownloadList(updatedDownloadList);
//   }, [isCheckAll, ImgUri, downloadImages]);

//   const handleDownload = async () => {
//     setIsDownloading(true);
//     if (currentDownloadList.length <= 10) {
//       for (const item of currentDownloadList) {
//         const [fileurl, downloadFileName, downloadFileNameExtension] = item.split("$");
//         try {
//           const response = await fetch(fileurl);
//           const blob = await response.blob();
//           const a = document.createElement("a");
//           const url = URL.createObjectURL(blob);
//           a.href = url;
//           a.download = `${downloadFileName}.${downloadFileNameExtension}`;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a); // Clean up
//           URL.revokeObjectURL(url);
//           setDownloadCount((prevCount) => prevCount + 1);
//         } catch (error) {
//           console.error("Error downloading file:", error);
//         }
//       }
//     } else {
//       const zip = new JSZip();
//       for (let index = 0; index < currentDownloadList.length; index++) {
//         const [fileurl, downloadFileName, downloadFileNameExtension] = currentDownloadList[index].split("$");
//         try {
//           const response = await fetch(fileurl);
//           const blob = await response.blob();
//           zip.file(`${downloadFileName}.${downloadFileNameExtension}`, blob);
//           setDownloadCount(index + 1); // Update the counter immediately for each file added to the zip
//           if (index === currentDownloadList.length - 1) {
//             const content = await zip.generateAsync({ type: "blob" });
//             saveAs(content, "WorkOrder_" + wo + ".zip");
//           }
//         } catch (error) {
//           console.error("Error downloading file:", error);
//         }
//       }
//     }
//     setIsDownloading(false);
//   };  

//   const buttonText = currentDownloadList.length > 10 ? "Download Zip" : "Download";

//   return (
//       <button
//         className={`btn-download ${isDownloading ? "is-downloading" : ""}`}
//         onClick={handleDownload}
//         disabled={isDownloading || !currentDownloadList.length || isCheck === 0}
//       >
//         {isDownloading ? `Downloading...(${Math.round(downloadCount/totalCount*100)}%)` : buttonText}
//       </button>
//   );
// }

// export default DownloadButton;